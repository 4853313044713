import * as moment from 'moment';

import { BadgeEvent, BadgeUpdate } from '../models/event.model';
import {
    AddUserToAccountRequest,
    AssignNfcResponse,
    BulkPersonUpdateRequest,
    CreateNewUserPersonRequest,
    GetValidationCodeRequest,
    PendingPerson,
    PendingPersonUpdateRequest,
    Person,
    PersonUpdate,
    RequestedPerson,
    RequestedUserActionRequest,
    SearchedUser,
    UserInviteActionRequest,
    UserUnblockActionRequest,
} from '../models/person.model';
import { CacheContext, HttpService } from './http.service';

import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { Topic } from '@weavix/models/src/topic/topic';
import { AccountService } from './account.service';
import { AlertService } from './alert.service';
import { PubSubService } from './pub-sub.service';

export enum DeviceStatus {
    Active = 'active', // within a minute activity
    Inactive = 'inactive', // within 10 minutes activity
    Offline = 'offline', // last activity 1+ day ago
    Unregistered = 'unregistered'
}

export interface NotificationProps {
    mobileLink: boolean; // Send link to download mobile app
    waltQr: boolean; // Send QR to complete registration on walt
}

@Injectable({
    providedIn: 'root',
})
export class PersonService {
    constructor(
        private httpService: HttpService,
        private pubSubService: PubSubService,
        private accountService: AccountService,
        private alertsService: AlertService,
    ) { }

    static readonly ACTIVE_DEVICE_TIME_MINUTES = 30;
    static readonly INACTIVE_DEVICE_TIME_DAYS = 1;
    static baseUrl = '/core/people';
    static facilityPeopleUrl = '/core/facility-people';
    static cacheCollection = 'people';
    private static readonly cacheContext: CacheContext = { collection: PersonService.cacheCollection, maxAge: 1800000 };

    private cache: { [id: string]: Person };
    private cacheCreated;

    private updateSubscription: Subscription;
    static url = (id?: string) => id ? `${PersonService.baseUrl}/${id}` : PersonService.baseUrl;

    static hasActiveDevice(p: Person) {
        return !!p.badge?.location // has a location
            && !p.badge?.offline
            && !!p.badge.facilities?.length;
    }

    static hasActivatedInactiveDevice(p: Person, atTime?: number) {
        return p.badge?.location // has a location
            && !p.badge?.offline && (!p.badge?.date
                || new Date(p.badge?.date).getTime() < moment(atTime).subtract(this.INACTIVE_DEVICE_TIME_DAYS, 'days').valueOf()); // outside inactive time
    }

    static isActiveEvent(e: BadgeEvent, atTime?: number) {
        return new Date(e?.date ?? 1).getTime() > moment(atTime).subtract(this.ACTIVE_DEVICE_TIME_MINUTES, 'minutes').valueOf(); // within active time
    }

    static inAnyFacility(badge: BadgeUpdate) {
        return badge?.facilities?.length > 0;
    }

    async getPeople(component, tags?: string[], cache: boolean = false): Promise<Person[]> {
        if (cache) {
            await this.checkCache(component);
            return tags ? Object.values(this.cache).filter(p => tags.some(t => p.tags && !!p.tags.find(t2 => t2 === t)))
                : Object.values(this.cache);
        }

        try {
            return await this.httpService.get<Person[]>(component, PersonService.url(), { tags }, null, (person: Person) => person.fullName);
        } catch (e) {
            this.alertsService.sendError(e, 'ERRORS.PEOPLE.GET');
            this.alertsService.setAppLoading(false);
            throw e;
        }
    }

    async getPendingPeople(component): Promise<PendingPerson[]> {
        try {
            return await this.httpService.get<PendingPerson[]>(component, `${PersonService.url()}/pending`, undefined, null, (person: PendingPerson) => person.fullName);
        } catch (e) {
            this.alertsService.sendError(e, 'ERRORS.PEOPLE.GET');
            this.alertsService.setAppLoading(false);
            throw e;
        }
    }

    async getRequestedPeople(component): Promise<RequestedPerson[]> {
        try {
            return await this.httpService.get<RequestedPerson[]>(component, `${PersonService.url()}/requested`, undefined, null, (person: RequestedPerson) => person.fullName);
        } catch (e) {
            this.alertsService.sendError(e, 'ERRORS.PEOPLE.GET');
            this.alertsService.setAppLoading(false);
            throw e;
        }
    }

    async updatePending(component, pendingData: PendingPersonUpdateRequest) {
        return await this.httpService.put<Person>(component, `${PersonService.url()}/pending`, pendingData);
    }

    async getFacilityPeople(component, facilityId: string, tags?: string[], useCache?: boolean) {
        try {
            const cacheParam = useCache !== false ? PersonService.cacheContext : null;
            return await this.httpService.get<Person[]>(component, `${PersonService.facilityPeopleUrl}/${facilityId}`, { tags }, cacheParam, (person: Person) => person.fullName);
        } catch (e) {
            this.alertsService.sendError(e, 'ERRORS.PEOPLE.GET');
            this.alertsService.setAppLoading(false);
            throw e;
        }
    }

    getStaticPerson(id: string) {
        return this.cache && this.cache[id];
    }

    async getPerson(component, id: string, cache: boolean = false, skipFetchIfNotInCache: boolean = false) {
        if (cache) {
            await this.checkCache(component);
            if (this.cache[id]) return this.cache[id];
        }
        if (skipFetchIfNotInCache) return null;

        try {
            return await this.httpService.get<Person>(component, PersonService.url(id), null, null);
        } catch (e) {
            this.alertsService.sendError(e, 'ERRORS.PEOPLE.GET');
            this.alertsService.setAppLoading(false);
            throw e;
        }
    }

    async createUserAndAddToAccount(component, newUser: CreateNewUserPersonRequest) {
        const data = await this.httpService.post<Person>(component, `${PersonService.url()}/new-user`, newUser);
        if (this.cache) this.cache[data.id] = data;
        return data;
    }

    async addUserToAccount(component, addUser: AddUserToAccountRequest) {
        const data = await this.httpService.post<Person>(component, `${PersonService.url()}/add-user`, addUser);
        if (this.cache) this.cache[data.id] = data;
        return data;
    }

    async inviteNewUser(component, newUser: CreateNewUserPersonRequest) {
        return this.httpService.post<PendingPerson>(component, `${PersonService.url()}/invite-new-user`, newUser);
    }

    async inviteUser(component, addUser: AddUserToAccountRequest) {
        return this.httpService.post<PendingPerson>(component, `${PersonService.url()}/invite-user`, addUser);
    }

    async resendUserInvite(component, request: UserInviteActionRequest) {
        return this.httpService.post<PendingPerson>(component, `${PersonService.url()}/resend-invite`, request);
    }

    async revokeUserInvite(component, request: UserInviteActionRequest) {
        return this.httpService.post<void>(component, `${PersonService.url()}/revoke-invite`, request);
    }

    async unblockUser(component, request: UserUnblockActionRequest) {
        return this.httpService.post<Person>(component, `/account/unblock-user`, request);
    }

    async sendValidationCode(component, request: GetValidationCodeRequest) {
        return this.httpService.post<Person>(component, `/account/send-pin-validation-code`, request);
    }

    async rejectRequestedUser(component, request: RequestedUserActionRequest) {
        return this.httpService.post<void>(component, `${PersonService.url()}/reject-requested`, request);
    }

    async updatePerson(component, id: string, personData: PersonUpdate): Promise<Person> {
        const data = await this.httpService.put<Person>(component, PersonService.url(id), personData);
        if (this.cache) this.cache[data.id] = data;
        return data;
    }

    async bulkUpdatePeople(component, request: BulkPersonUpdateRequest) {
        const { updated, errors } = await this.httpService.put<any>(component, `${PersonService.url()}/bulk-update`, request);
        if (this.cache) updated.forEach(p => this.cache[p.id] = p);
        if (errors?.length) {
            console.error(errors);
            throw new Error('ERRORS.PEOPLE.UPDATE');
        }
        return updated;
    }

    async deletePerson(component, id: string): Promise<Person> {
        const result = await this.httpService.delete<Person>(component, PersonService.url(id), null);
        if (this.cache) delete this.cache[id];
        return result;
    }

    async assignNfc(component, id: string): Promise<AssignNfcResponse> {
        return await this.httpService.put<AssignNfcResponse>(component, `/core/users/${id}/assign-nfc`, {});
    }

    async activateNfc(component, id: string, token: string): Promise<void> {
        return await this.httpService.put<void>(component, `/core/users/${id}/activate-nfc/${token}`, {});
    }

    async checkCache(component) {
        if (!this.updateSubscription) {
            this.updateSubscription = this.accountService.account$.subscribe(async account => {
                const sub = await this.pubSubService.subscribe<Person>(null, Topic.AccountPersonUpdated, [account.id, '+']);
                sub.subscribe(payload => {
                    if (Object.keys(payload.payload).length) {
                        this.cache[payload.topic[1]] = payload.payload;
                    } else {
                        delete this.cache[payload.topic[1]];
                    }
                });
            });
        }
        if (!this.cacheCreated || this.cacheCreated.getTime() < new Date().getTime() - 1800000) {
            try {
                const people = await this.httpService.get<Person[]>(component, PersonService.url());
                this.cache = people.reduce((arr, p) => (arr[p.id] = p, arr), {} as any);
                this.cacheCreated = new Date();
            } catch (e) {
                this.alertsService.sendError(e, 'ERRORS.PEOPLE.GET');
                this.alertsService.setAppLoading(false);
                throw e;
            }
        }
    }

    async searchUsers(component, text: string, facilityId?: string) {
        return this.httpService.get<SearchedUser[]>(component, `${PersonService.url()}/search-users`, { text, facilityId });
    }

    async subscribePersonUpdates(c: any) {
        return this.pubSubService.subscribe<Person>(c, Topic.AccountPersonUpdated, [this.accountService.getAccountId(), '+']);
    }

    async subscribeBadgeUpdates(c: any, id?: string) {
        return this.pubSubService.subscribe<BadgeUpdate>(c, Topic.AccountPersonBadgeUpdated, [this.accountService.getAccountId(), id ?? '+']);
    }

    async subscribeFacilityBadgeUpdates(c: any, facilityId: string) {
        return this.pubSubService.subscribe<BadgeUpdate>(c, Topic.AccountFacilityPersonBadgeUpdated, [this.accountService.getAccountId(), facilityId, '+']);
    }

    async checkWisp(component, wispId: string): Promise<string> {
        return this.httpService.get(component, `/core/wisps/wisp-${wispId}`);
    }

    async unAssignWisp(component, wispId: string) {
        return this.httpService.put(component, `/core/wisps/wisp-${wispId}/remove`, {});
    }

    async assignWisp(component, wispId: string, personId: string) {
        const body = {
            entityId: personId,
            wispEntity: 'person',
        };
        return this.httpService.put(component, `/core/wisps/wisp-${wispId}/assign`, body);
    }
}
