import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { AutoUnsubscribe, Utils } from 'weavix-shared/utils/utils';
import { DropdownItem } from '../dropdown/dropdown.model';

@AutoUnsubscribe()
@Component({
    selector: 'app-day-time-picker',
    templateUrl: './day-time-picker.component.html',
    styleUrls: ['./day-time-picker.component.scss'],
})
export class DayTimePickerComponent implements OnInit {
    @Input() label: string;
    @Input() value: number;
    @Output() valueChange: EventEmitter<number> = new EventEmitter();

    private readonly roundingInterval = 15 * 60 * 1000; /*ms*/
    tzDiff: number;
    dropdownItems: DropdownItem[];
    form: FormGroup;

    constructor( private fb: FormBuilder ) { }

    async ngOnInit() {
        console.log(`\nINIT`);
        await this.initializeForm(this.value);
    }

    async initializeForm(time: number) {
        console.log(`\nTIME -> ${time}`);
        this.value = time;
        this.dropdownItems = this.getDropdownItems();
        this.buildForm();
    }

    private getDropdownItems(): DropdownItem[] {
        const dropdownItems: DropdownItem[] = [];
        let timeItem = moment(this.value).startOf('day').valueOf();
        const endOfDay = moment(this.value).endOf('day').valueOf();

        do {
            dropdownItems.push({
                label: moment(timeItem).format('hh:mm a'),
                key: timeItem,
            });
            timeItem = timeItem + this.roundingInterval;
        } while (timeItem <= endOfDay);

        return dropdownItems;
    }

    private buildForm(): void {
        let initialValue;
        if (!this.dropdownItems.find(d => d.key === this.value)) initialValue = this.dropdownItems[0].key;
        else initialValue = this.value;

        this.form = this.fb.group({ time: initialValue });
        Utils.safeSubscribe(this, this.form.controls.time.valueChanges).subscribe(time => {
            this.valueChange.emit(time);
        });
    }
}
